import { env } from '../../environments';
import { MAP_APIS_ENDPOINTS } from '../../services/EndPoints';
import { API } from '../axios';
import { SecurityProvider } from '../security';

const auth = env.ql_token;
export const streetQuery = `query getStreet($input: ID!) {
  street(streetId: $input) {
    lat
    lon
    middleNumber
    streetId
    streetName
    streetType
    streetTypeAbbr
    localityName
    state
    post
    turnoverRate
    streetMedian {
      year
      median
    }
    streetSold {
        propId
      soldPrice
      soldContractDate
    }
    suburbMedianPct
    median
    housePriceRange {
        soldPriceRange{
            minValue
            maxValue
        }
        numberOfYears
        landValueRange{
            minValue
            maxValue
        }
        landSizeRange{
            minValue
            maxValue
        }
        medianPrice {
            median
            numberOfSold
        }
    }
    apartmentPriceRange {
        soldPriceRange{
            minValue
            maxValue
        }
        numberOfYears
        medianPrice {
            median
            numberOfSold
        }
    }
    numberOfHouse
    numberOfApartment
    numberOfProject
  }
}`;

const queryLocality = `query getLocality($input :ID!) {
    locality(localityId : $input) {
        streetCards{
          streetId
          suburbMedianPct
          holdingYear
        }

        streetRanking {
          mostExpensive {
            streetId
            streetName
            localityName
            turnoverRate
            suburbMedianPct
            median
          }
          mostEconomical {
            streetId
            streetName
            localityName
            turnoverRate
            suburbMedianPct
            median
          }
          highestChanginghands {
            streetId
            streetName
            localityName
            turnoverRate
            suburbMedianPct
            median
          }
          lowestChanginghands {
            streetId
            streetName
            localityName
            turnoverRate
            suburbMedianPct
            median
          }
       }
  
        bigSector {
          bigSectorCode
          bigSectorName
          priceHouseTrend {
            month
            monthlyMedian
            monthlySold
          }
        }
        sector {
          sectorCode
          priceTrendHouse {
            month
            monthlyMedian
            monthlySold
          }
        }
        shoppingCentre {
          centreName
          distance
        }
        chineseKeyword
        chineseComment
        rateShopping
        familyIncome
        chinese
        hinduismPPpct
        islamPPct
        rentalPct
        houseTurnOverRate
        landvaluePctMedian
        cbdDistance
        chineseKeyword
        chineseComment
        significantProjects {
          projectName
          completionTime
        }
        religionRank {
          religionName
          religionPercent
        }
        ancestryRank {
          topAncestryName
          topAncestryPct
        }
        medianAge
        flatApartmentTotal
        houseTotal
        familyWithChildren
        familyWithoutChildren
        familyOther
        transport {
          drive_in_trafic
          transit_in_trafic
        }
        topOccupations {
          first {
            name
          }
          second {
            name
          }
          third {
            name
          }
          fourth {
            name
          }
          fifth {
            name
          }
        }
        city {
          houseTurnOverRate
        }
        

        localityName
        polygonEncoded
        intersectedPrimary {              
            schoolName
            polyEncoded
            school {
              ranking
              starRating
              icsea
              enrolments
            }
        }
        medianData {
          house {
            total {
              median
            }
          }
        }
        priceTrendHouse {
          year
          yearly_median
        }
      
    }
}`;

const query = `query getProperty($input: ID!) {
    property(propId: $input) {
      __typename
      ... on House {
        propId
        stdAddress
        landSize
        dataId
        propX
        propY
        propCarpark
        sectorMedian
        soldRecords {
          soldPrice
          soldContractDate
        }
        propType 
        propBed 
        propBath
        propType

        propInfo {
          propHeritageType
          propPriceCompare {
            streetPriceHigher
            streetTurnoverRate
          }
          stationDistance {
            closestStationWalkTime
          }
        }


        mainstreamBedHouse
        propZoning
       
        landValue
      
        primarySchools {
          lbotePct
          schoolName
          ranking
          starRating
          enrolments
          icsea
          lat
          lon
          yearlyRanking {
            year
            ranking
            
          }

        }
        secondarySchools {
          lbotePct
          schoolName
          enrolments
          icsea
          ranking
          starRating
          lat
          lon
          yearlyRanking {
            year
            ranking           
          }
        }

       
        closestStationDistance
        closestStationName

      

        landValue
        street {
          turnoverRate
          streetName
          streetId
          state
          post
          localityId
          localityName
          suburbMedianPct
          streetSold {
            soldPrice
            soldContractDate
          }
          streetMedian {
            year
            median
          }

        }
        locality {
          significantProjects {
            projectName
            completionTime
          }
          religionRank {
            religionName
            religionPercent
          }
          ancestryRank {
            topAncestryName
            topAncestryPct
          }
          bigSector {
            bigSectorCode
            bigSectorName
            priceHouseTrend {
              month
              monthlyMedian
              monthlySold
            }
          }
          sector {
            sectorCode
            priceTrendHouse {
              month
              monthlyMedian
              monthlySold
            }
          }
          shoppingCentre {
            centreName
            distance
          }
          flatApartmentTotal
          houseTotal
          chineseKeyword
          chineseComment
          rateShopping
          familyIncome
          chinese
          hinduismPPpct
          islamPPct
          rentalPct
          houseTurnOverRate
          landvaluePctMedian
          cbdDistance
          chineseKeyword
          chineseComment
          significantProjects {
            projectName
            completionTime
          }
          medianAge
          familyWithChildren
          familyWithoutChildren
          familyOther
          transport {
            drive_in_trafic
            transit_in_trafic
          }
          topOccupations {
            first {
              name
            }
            second {
              name
            }
            third {
              name
            }
            fourth {
              name
            }
            fifth {
              name
            }
          }
          city {
            houseTurnOverRate
          }

          localityName
          localityId
          polygonEncoded
          intersectedPrimary {              
              schoolName
              polyEncoded
              school {
                ranking
                icsea
                enrolments
                starRating
              }
          }
          medianData {
            house {
              total {
                median
              }
            }
          }
          priceTrendHouse {
            year
            yearly_median
          }
        }
        selectiveSchools {
          schoolId
          schoolName
          lat
          lon
          schoolSex
          polygonEncoded
          ranking
          starRating
        }
        privateSchools {
          schoolId
          schoolName
          lat
          lon
          schoolSex
          polygonEncoded
          ranking
          starRating

        }
        hazard {
          lat
          lon
          hazardType
        }
        businessPark {
          name
          numberOfJobs {
            year 
            employees
          }
          mainIndustries
          lat
          lon
          distance
        }
      }
      ... on Apartment {
        propId
        stdAddress
        quietAve
        propBed
        propCarpark
        propBath
        propZoning
        street {
          turnoverRate
          streetName
          streetId
          state
          post
          localityId
          localityName
        }
        primarySchools {
          lbotePct
          schoolName
          enrolments
          icsea
          yearlyRanking {
            year
            ranking
          }
          starRating

        }
        secondarySchools {
          lbotePct
          schoolName
          enrolments
          icsea
          yearlyRanking {
            year
            ranking
          }
          starRating
        }
        locality {
          significantProjects {
            projectName
            completionTime
          }
          religionRank {
            religionName
            religionPercent
          }
          ancestryRank {
            topAncestryName
            topAncestryPct
          }
          flatApartmentTotal
          houseTotal
          rateShopping
          familyIncome
          chinese
          hinduismPPpct
          islamPPct
          rentalPct
          houseTurnOverRate
          cbdDistance
          chineseKeyword
          chineseComment
          shoppingCentre {
            centreName
            distance
          }
          city {
            houseTurnOverRate
          }

          localityName
          polygonEncoded
          intersectedPrimary {
              
              schoolName
              polyEncoded
              school {
                ranking
                icsea
                enrolments
                starRating
              }
          }
          localityPost
          localityId
          medianData {
            apt {
              twoBeds {
                median
              }
            }
          }
        }
      }
      ... on ApartmentLike {
        propId
        stdAddress
        
        propBed
        propCarpark
        
        street {
          turnoverRate
          streetName
          streetId
          state
          post
          localityId
          localityName
        }
        primarySchools {
          lbotePct
          schoolName
          enrolments
          icsea
          lat
          lon
          starRating
          yearlyRanking {
            year
            ranking
          }

        }
        secondarySchools {
          lbotePct
          schoolName
          enrolments
          icsea
          lat 
          lon
          starRating
          yearlyRanking {
            year
            ranking
          }
        }
       
        
        locality {
          significantProjects {
            projectName
            completionTime
          }
          religionRank {
            religionName
            religionPercent
          }
          ancestryRank {
            topAncestryName
            topAncestryPct
          }
          flatApartmentTotal
          houseTotal
          rateShopping
          familyIncome
          chinese
          hinduismPPpct
          islamPPct
          rentalPct
          houseTurnOverRate
          cbdDistance
          chineseKeyword
          chineseComment
          shoppingCentre {
            centreName
            distance
          }
          city {
            houseTurnOverRate
          }

          localityName
          polygonEncoded
          intersectedPrimary {
              
              schoolName
              polyEncoded
              school {
                ranking
                icsea
                enrolments
                starRating
              }
          }
          localityPost
          localityId
          medianData {
            apt {
              twoBeds {
                median
              }
            }
          }
        }
      }
    }
  }
  `;

export const queryPropEnhance_byGraphQL = async (propId) => {
    const query = `query getProperty($input: ID!) {
    property(propId: $input) {
      __typename
      ... on House {
        propId
        stdAddress
        landSize
        dataId
        propX
        propY
        propCarpark
        sectorMedian
        propType 
        propBed 
        propBath
        mainstreamBedHouse
        propZoning
        landValue
        soldRecords {
          soldPrice
          soldContractDate
        }
        propInfo {
          propHeritageType
          propPriceCompare {
            streetPriceHigher
            streetTurnoverRate
          }
          stationDistance {
            closestStationWalkTime
          }
        }
        primarySchools {
          lbotePct
          schoolName
         
          starRating
          enrolments
          icsea
          lat
          lon
     
        }
        secondarySchools {
          lbotePct
          schoolName
          enrolments
          icsea
        
          starRating
          lat
          lon
       
        }
        closestStationDistance
        closestStationName
        landValue
        street {
          turnoverRate
          streetName
          streetId
          state
          post
          localityId
          localityName
          suburbMedianPct
          streetSold {
            soldPrice
            soldContractDate
          }
          streetMedian {
            year
            median
          }

        }
        locality {
          flatApartmentTotal
          houseTotal
          chineseKeyword
          chineseComment
          rateShopping
          familyIncome
          chinese
          hinduismPPpct
          islamPPct
          rentalPct
          houseTurnOverRate
          landvaluePctMedian
          cbdDistance
          chineseKeyword
          chineseComment
          significantProjects {
            projectName
            completionTime
          }
          medianAge
          familyWithChildren
          familyWithoutChildren
          familyOther
          transport {
            drive_in_trafic
            transit_in_trafic
          }
          
          city {
            houseTurnOverRate
          }

          localityName
          localityId
          polygonEncoded
          intersectedPrimary {              
              schoolName
              polyEncoded
              school {
                ranking
                starRating
                icsea
                enrolments
              }
          }
          medianData {
            house {
              total {
                median
              }
            }
          }
          priceTrendHouse {
            year
            yearly_median
          }
        }
        selectiveSchools {
          schoolId
          schoolName
          lat
          lon
          schoolSex
          polygonEncoded
       
          starRating
        }
        privateSchools {
          schoolId
          schoolName
          lat
          lon
          schoolSex
          polygonEncoded
        
          starRating
        }
      }
      ... on Apartment {
        propId
        stdAddress
        landAddress
        quietAve
        propType 
        propBed
        propBath
        propCarpark
        propZoning
        propY
        propX
        closestStationDistance
        closestStationName
        propInfo {
          propHeritageType
          propPriceCompare {
            streetPriceHigher
            streetTurnoverRate
          }
          stationDistance {
            closestStationWalkTime
          }
        }
        primarySchools {
          lbotePct
          schoolName
         
          enrolments
          icsea
          lat
          lon
          starRating
      
        }
        secondarySchools {
          lbotePct
          schoolName
          enrolments
          icsea
         
          lat
          lon
          starRating
  
        }
        selectiveSchools {
          schoolId
          schoolName
          lat
          lon
          schoolSex
          polygonEncoded
         
          starRating
        }
        privateSchools {
          schoolId
          schoolName
          lat
          lon
          schoolSex
          polygonEncoded
         
          starRating
        }
        
        soldRecords {
          soldPrice
          soldContractDate
        }
        street {
          turnoverRate
          streetName
          streetId
          state
          post
          localityId
          localityName
          suburbMedianPct
          streetSold {
            soldPrice
            soldContractDate
          }
          streetMedian {
            year
            median
          }

        }
        locality {
          flatApartmentTotal
          houseTotal
          chineseKeyword
          chineseComment
          rateShopping
          familyIncome
          chinese
          hinduismPPpct
          islamPPct
          rentalPct
          houseTurnOverRate
          landvaluePctMedian
          cbdDistance
          chineseKeyword
          chineseComment
          significantProjects {
            projectName
            completionTime
          }
          medianAge
          familyWithChildren
          familyWithoutChildren
          familyOther
          transport {
            drive_in_trafic
            transit_in_trafic
          }
          
          city {
            houseTurnOverRate
          }

          localityName
          localityId
          polygonEncoded
          intersectedPrimary {              
              schoolName
              polyEncoded
              school {
                ranking
                starRating
                icsea
                enrolments
              }
          }
          medianData {
            house {
              total {
                median
              }
            }
          }
        }
      }
      ... on ApartmentLike {
        propId
        stdAddress
        
        propBed
        propCarpark
        
        street {
          turnoverRate
          streetName
          streetId
          state
          post
          localityId
          localityName
        }
        primarySchools {
          lbotePct
          schoolName
          enrolments
          icsea
          lat
          lon
          yearlyRanking {
            year
            ranking
          }
          starRating

        }
        secondarySchools {
          lbotePct
          schoolName
          enrolments
          icsea
          lat 
          lon
          yearlyRanking {
            year
            ranking
          }
          starRating
        }
       
        
        locality {
          significantProjects {
            projectName
            completionTime
          }
          religionRank {
            religionName
            religionPercent
          }
          ancestryRank {
            topAncestryName
            topAncestryPct
          }
          flatApartmentTotal
          houseTotal
          rateShopping
          familyIncome
          chinese
          hinduismPPpct
          islamPPct
          rentalPct
          houseTurnOverRate
          cbdDistance
          chineseKeyword
          chineseComment
          shoppingCentre {
            centreName
            distance
          }
          city {
            houseTurnOverRate
          }

          localityName
          polygonEncoded
          intersectedPrimary {
              
              schoolName
              polyEncoded
              school {
                ranking
                icsea
                enrolments
                starRating
              }
          }
          localityPost
          localityId
          medianData {
            apt {
              twoBeds {
                median
              }
            }
          }
        }
      }
    }
  }
  `;
    return API.post(
        null,
        MAP_APIS_ENDPOINTS.PROPERTY_GRAPHQL,
        {
            query,
            variables: { input: propId },
        },
        {
            authorization: auth,
        },
    );
};

export const queryProp_byGraphQL = async (propId) => {
    return API.post(
        null,
        MAP_APIS_ENDPOINTS.PROPERTY_GRAPHQL,
        {
            query,
            variables: { input: propId },
        },
        {
            authorization: auth,
        },
    );
    // const resp = await fetch(`${env.apiHost}/graphql`, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         apiKey: securityProvider.tokenInfo?.value?.token,
    //         Accept: 'application/json',
    //     },
    //     body: JSON.stringify({
    //         query,
    //         variables: { input: propId },
    //     }),
    // });
    // return resp.json();
};

export const queryLocality_byGraphQL = async (localityId) => {
    return API.post(
        null,
        `${env.apiHost}/graphql`,
        {
            query: queryLocality,
            variables: { input: localityId },
        },
        {
            authorization: auth,
        },
    );
};

export const queryStreetRank = async (localityId) => {
    const queryQL = `query getLocality($input :ID!) {
      locality (localityId :$input){
      localityName  
      streetRanking {
        mostExpensive {
          streetId
          streetName
          localityName
          turnoverRate
          suburbMedianPct
          median
        }
        mostEconomical {
          streetId
          streetName
          localityName
          turnoverRate
          suburbMedianPct
          median
        }
        highestChanginghands {
          streetId
          streetName
          localityName
          turnoverRate
          suburbMedianPct
          median
        }
        lowestChanginghands {
          streetId
          streetName
          localityName
          turnoverRate
          suburbMedianPct
          median
        }
     }
   }
  }`;
    return API.post(null, MAP_APIS_ENDPOINTS.PROPERTY_GRAPHQL, {
        query: queryQL,

        variables: { input: localityId },
    });
};

export const query_byGraphQL = async (query, streetId) => {
    return API.post(
        null,
        MAP_APIS_ENDPOINTS.PROPERTY_GRAPHQL,
        {
            query,
            variables: { input: streetId },
        },
        {
            authorization: auth,
        },
    );
};
export async function api_getPropertyType(propId) {
    const query = `query getProperty($input: ID!) {
    property(propId: $input) {
      __typename
      
    }
  }`;
    return API.post(
        null,
        MAP_APIS_ENDPOINTS.PROPERTY_GRAPHQL,
        {
            query,
            variables: {
                input: propId,
            },
        },
        {
            authorization: auth,
        },
    );
}
export async function api_searchGoogleResult(propId) {
    const query = `query getProperty($input: ID!) {
      property(propId: $input) {
        __typename,
         ... on House {
          stdAddress
          propX
          propY
          moreInfoGoogle {
            realestateInfo {
              title
              link
              imagelink
            }
            domainInfo{
              title
              link
              imagelink
            }
          }
        }
      }
    }`;

    return API.post(
        null,
        MAP_APIS_ENDPOINTS.PROPERTY_GRAPHQL,
        {
            query,
            variables: {
                input: propId,
            },
        },
        {
            authorization: auth,
        },
    );
}

export const queryLocalityHistoryMedian = async (localityId, startYear, endYear) => {
    const query = `query getLocality($input: ID!,$startYear:Int,$endYear:Int) {
    locality(localityId: $input,startYear:$startYear,endYear:$endYear) {
         localityId
         intersectedPrimary {
          school {
            schoolId
            lbotePct
            schoolName
            ranking
            starRating
            enrolments
            icsea
            lat
            lon

          }
          schoolName
          polyEncoded
        }
        historicalTransactions {
          year
          priceHouse
          priceApt
        }
    }
  }`;
    return API.post(
        null,
        MAP_APIS_ENDPOINTS.PROPERTY_GRAPHQL,
        {
            query,
            variables: {
                input: localityId,
                startYear: startYear,
                endYear: endYear,
            },
        },
        {
            authorization: auth,
        },
    );
};

export const queryAdjacent_byGraphQL = async (propId, intervals, distance, bedRooms, proptype) => {
    const query = `query getProperty($input: ID!,$adjacent : Boolean,$distance:Float,$intervals:Int,$bedrooms : [Int], $propType: String) {
    property(propId: $input,adjacent : $adjacent,distance : $distance,intervals : $intervals ,bedrooms : $bedrooms, propType: $propType) {
      __typename,
       ... on House {
          propId
          stdAddress 
          locality {
            polygonEncoded
          }
          adjacentSold {
            propId
            fullAddress
            propX
            propY
            propBed
            propBath
            propCarpark
            soldPrice
            soldContractDate
          }   
        }
        ... on Apartment {
          propId
          stdAddress 
          locality {
            polygonEncoded
          }
          adjacentSold {
            propId
            fullAddress
            propX
            propY
            propBed
            propBath
            propCarpark
            soldPrice
            soldContractDate
          }   
        }
    }
  }`;
    console.log(query);
    return API.post(
        null,
        MAP_APIS_ENDPOINTS.PROPERTY_GRAPHQL,
        {
            query,
            variables: {
                input: propId,
                adjacent: true,
                distance: distance,
                intervals: intervals,
                bedrooms: bedRooms,
                propType: proptype,
            },
        },
        {
            authorization: auth,
        },
    );
};

export const statsQuery_byGraphQL = async () => {
    const query = `query getStatsInfo {
      statsInfo {
        propertiesSoldInLast15Days,
        propertiesTaggedInLast15Days,
        localityAptMedian {
          updateTime,
          localityMedian6MChange {
            localityMedianTopChange {
              id,
              name,
              median,
              medianChange
            },
            localityMedianBottomChange {
              id,
              name,
              median,
              medianChange
            },
            localityMedianTopChangeRate {
              id,
              name,
              median,
              medianChange
            },
            localityMedianBottomChangeRate {
              id,
              name,
              median,
              medianChange
            }
          }
          localityMedian12MChange {
            localityMedianTopChange {
              id,
              name,
              median,
              medianChange
            },
            localityMedianBottomChange {
              id,
              name,
              median,
              medianChange
            },
            localityMedianTopChangeRate {
              id,
              name,
              median,
              medianChange
            },
            localityMedianBottomChangeRate {
              id,
              name,
              median,
              medianChange
            }
          }
        },
        localityHouseMedian {
          updateTime,
          localityMedian6MChange {
            localityMedianTopChange {
              id,
              name,
              median,
              medianChange
            },
            localityMedianBottomChange {
              id,
              name,
              median,
              medianChange
            },
            localityMedianTopChangeRate {
              id,
              name,
              median,
              medianChange
            },
            localityMedianBottomChangeRate {
              id,
              name,
              median,
              medianChange
            }
          }
          localityMedian12MChange {
            localityMedianTopChange {
              id,
              name,
              median,
              medianChange
            },
            localityMedianBottomChange {
              id,
              name,
              median,
              medianChange
            },
            localityMedianTopChangeRate {
              id,
              name,
              median,
              medianChange
            },
            localityMedianBottomChangeRate {
              id,
              name,
              median,
              medianChange
            }
          }
        }    
      }
    }`;
    return API.post(
        null,
        MAP_APIS_ENDPOINTS.PROPERTY_GRAPHQL,
        {
            query,
            variables: {},
        },
        {
            authorization: auth,
        },
    );
};
