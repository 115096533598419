import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// import { TR } from '../../../../commons';
import { PopupComponentForLabel } from './../../../PriceMap/components/popupComponentForLabel';
import { divide, property } from 'lodash';
import { LandSize } from './../../../PriceMap/support/PriceMapConsts';
import { Landscape } from '@material-ui/icons';
import { convertToM } from '../../../../commons/utils';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { rst } from 'rt-state';
import { clsx, formatDate, formatDateForEn, RRLink, SecurityProvider, storage, TR, TRHtml } from '../../../../commons';
import { TrialButton } from '../../../auth/UserAuthButton/TrialButton';
import { LoginOrSignupButton } from '../../../auth/UserAuthButton/LoginOrSignupButton';
import { AppProvider } from '../../../../commons/appProvider';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { PropBeds } from '../../../PriceMap/Layers/PropertyInfo/PropBeds';
import { MedianPrice } from './../../../Home/MedianPrice';
import { stgEnv } from './../../../../environments/stg';
import {
    getFavouriteDtoForProperty,
    getFavourites,
    removeFavourite,
    saveFavourite,
} from '../../../../services/userCenter';
import { UserCenterEntityType } from '../../../../models/backend/userCenter';
import { SalePriceHistory } from '../salePriceHistory';
import { MobileLocalityPriceTrend } from '../chart/mobileLocalityPriceTrend';
import { queryProp_byGraphQL } from '../../../../commons/graphqlClient/graphAPI';
import { Card11 } from '../../../PropertyReport/ReportCards/Card11_GeneralPage';

const useStyles = makeStyles({
    root: {
        padding: '6px 13px',
        // width: '72px',

        height: '30px',
        borderRadius: '16px',
        border: '1px solid rgba(151,151,151,0.65)',
        // marginLeft: '42px',
        display: 'flex',

        alignItems: 'center',
    },
    title: {
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'PingFangSC, PingFangSC-Medium',
        color: '#333333',
    },
    label: {
        dispaly: 'flex',
        alighContent: 'flex-start',
        padding: '16px',
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        height: '30px',
        background: '#f7f7f7',
        border: '1px solid rgba(151,151,151,0.2)',
        borderRadius: '4px',
        marginTop: '10px',
        padding: '6px',
    },
    linkShowing: {
        // padding: '1.5px',
        fontSize: '13px',
        minHeight: '18px',
        fontFamily: 'PingFangSC, PingFangSC-Regular',
        fontWeight: 400,
        textAlign: 'left',
        color: '#333333',
        lineHeight: '18px',
        letterSpacing: '1px',
        padding: '1px',
        // fontSize: 12,
    },
    favoriteIcon: {
        padding: '',
        width: '18px',
        minHeight: '18px',
    },
    // line: {
    //     background: 'grey',
    //     width: '0.6px',
    //     minHeight: '',
    //     positioni: 'relative',
    //     // left: '10px',
    //     float: 'left',
    //     margin: '0 4px',
    // },
    labelLine1: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '1px 0',
    },
    labelLine3: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    button: {
        border: 'none',
        background: 'none',
        padding: 'none',
    },
    tableName: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#333333',
        marginTop: '3pt',
        marginBottom: '3pt',
        textAlign: 'left',
        fontFamily: 'PingFangSC, PingFangSC-Medium',
        // height: '20px',
        // lineHeight: '20px',
    },
    tableRow: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    tableCell: {
        fontSize: '13px',
        // height: '18px',
        // lineHeight: '18px',
        fontWeight: 400,
        color: '#333333',
        padding: '3px 11px 3px 10px',
        margin: '6px 9px 14px 0px',
        backgroundColor: '#F1F1F2',
        borderRadius: '4px',
        width: 'fit-content',
        fontFamily: 'PingFangSC, PingFangSC-Regular',
        textSlign: 'left',
    },
    content: {
        fontSize: '14px',
        fontFamily: 'PingFangSC, PingFangSC-Regular',
        fontWeight: 400,
        textSlign: 'justify',
        color: '#666666',
    },

    moreInfo: {
        width: '40pt',
        minHeight: '40pt',
    },
    image: {
        minWidth: '14px',
        minHeight: '14px',
        marginLeft: 5,
        paddingRight: 1,
    },
    googleSearchDiv: {
        fontSize: 14,
        // marginLeft: 25,
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 6,
    },
});

export const ApartmentLabel = (props) => {
    const appProvider = AppProvider.use();
    const { snackbarController } = appProvider;
    console.log(props);

    const [faved, setFaved] = useState(false);
    const [streetSolds, setStreetSolds] = useState(null);
    const classes = useStyles();
    const { googleSearchEvt } = props;
    const {
        propBed,
        propBath,
        propCarpark,
        landSize,
        landValue,
        propZoning,
        propType,
        closestStationDistance,
        closestStationName,
        stdAddress,
        propId,
        soldRecords,
        locality,
    } = props.property;
    const [suburbHistoryPrices, setSuburbHistoryPrices] = useState(null);

    const soldRecordsSort =
        soldRecords && soldRecords.sort((a, b) => (a.soldContractDate > b.soldContractDate ? -1 : 1));

    console.log('testtesttest', props.property.__typename);

    EventBus.on(EVENT_NAMES.EVENT_RETRIEVE_PROPERTY_ADJUST, () => {
        retrieveBedsFromStorage();
    });

    const [properBeds, setPropertyBeds] = useState({
        bed: propBed,
        bath: propBath,
        carpark: propCarpark,
    });
    const retrieveBedsFromStorage = () => {
        storage.get(`PROPERTY_BED_ADJUST_${propId}`).then((result: any) => {
            console.log(result);
            if (result) {
                setPropertyBeds({
                    bed: result.bed,
                    carpark: result.carpark,
                    bath: result.bath,
                });
            }
        });
    };

    const isPropInFavs = () => {
        getFavouriteDtoForProperty(propId).then(
            (result) => {
                setFaved(true);
            },
            (err) => {},
        );
    };

    useEffect(() => {
        retrieveBedsFromStorage();
        isPropInFavs();
        getStreetSoldTrendData();
    }, []);

    const { primaryRanking } = props;
    const { ranking, schoolName, icsea, starRating } = props.primary;
    const { housePriceRange, apartmentPriceRange } = props.street;

    const securityProvider = SecurityProvider.use();
    const hasLogin = () => {
        return securityProvider.hasLogin();
    };

    const showGoogleSearch = () => {
        googleSearchEvt(propId);
    };

    EventBus.on(EVENT_NAMES.EVENT_MOBILE_LOCALDATA, (evenData: any) => {
        setSuburbHistoryPrices(evenData.local);
    });
    console.log('suburb history price', suburbHistoryPrices);

    const handleClick = () => {
        // const f = appProvider.openLoginDialog(null);
        if (hasLogin()) {
            if (!faved) {
                saveFavourite({ entityType: UserCenterEntityType.Property, entityId: propId }).then((result) => {
                    snackbarController.open(<TR name={'Favorited'} />, 'success');
                    setFaved(true);
                });
            } else {
                removeFavourite(propId).then((result: any) => {
                    snackbarController.open(<TR name={'FavCanelled'} />, 'error');
                    setFaved(false);
                });
            }
        } else {
            return appProvider.openLoginDialog(null);
            // return alert('no');
        }
    };

    const getStreetSoldTrendData = () => {
        queryProp_byGraphQL(propId).then((propertyResult: any) => {
            if (propertyResult.errors) {
            } else {
                setStreetSolds(propertyResult.data);
            }
        });
    };

    return (
        <>
            <div className={classes.label}>
                <div className={classes.labelLine1}>
                    <PopupComponentForLabel
                        tagType={'beds'}
                        addtionData={{ propId: propId, bed: propBed, bath: propBath, carpark: propCarpark }}
                        link={
                            <div className={classes.link} style={{ marginTop: 0 }}>
                                <div className={classes.image}>
                                    <img src="assets/mobileIcons/ws-ico@1x.svg"></img>
                                </div>
                                <div style={{ marginLeft: 1 }} className={classes.linkShowing}>
                                    {properBeds.bed || '-'}
                                </div>
                                <div className={classes.image}>
                                    <img src="assets/mobileIcons/my-ico@1x.svg"></img>
                                </div>
                                <div style={{ marginLeft: 1 }} className={classes.linkShowing}>
                                    {properBeds.bath || '-'}
                                </div>
                                <div className={classes.image}>
                                    <img src="assets/mobileIcons/cz-ico@1x.svg"></img>
                                </div>
                                <div className={classes.linkShowing}>{properBeds.carpark || '-'}</div>
                                {/* <div className={classes.image}>
                                    <img src="assets/mobileIcons/mj-ico.svg"></img>
                                </div>

                                <div className={classes.linkShowing}>
                                    {new Intl.NumberFormat().format(landSize?.toFixed(0))}&#x33A1;{' '}
                                </div> */}

                                <div
                                    className={classes.linkShowing}
                                    style={{ marginLeft: 5, color: '#333333', fontSize: '10px' }}>
                                    <TR name="CorrectProperty"></TR>
                                </div>
                            </div>
                        }
                        content={
                            <div className={classes.content}>
                                {propBed}
                                <TR name={'bedroom'}></TR>
                                {properBeds.bed}
                                <TR name={'bathroom'}></TR>
                                {propCarpark}
                                <TR name={'carpark'}></TR>
                            </div>
                        }
                        title={
                            <div className={classes.title}>
                                <TR name={'houseType'}></TR>
                            </div>
                        }
                        table=""
                        maxWidth="xl"
                    />

                    <div
                        className={classes.root}
                        onClick={handleClick}
                        style={faved ? {} : { background: '#f7f7f7', color: 'black' }}>
                        {/* style={faved ? { background: '#009e90', color: 'white' } : {} } */}
                        {/* style={faved ? { background: '#009e90', color: 'white' } : {}}> */}
                        <img
                            className="classes.favoriteIcon"
                            src={`assets/mobileIcons/${faved ? 'faved' : 'unfaved'}.svg`}
                            width="30%"></img>
                        <div
                            style={{
                                marginLeft: 2,
                                fontSize: 13,
                                height: 18,
                                minWidth: 28,
                                fontWeight: 400,
                                textAlign: 'left',
                                color: faved ? 'black' : '#333333',
                            }}>
                            {' '}
                            <TR name="favHouse"></TR>
                        </div>
                    </div>
                </div>

                <div className={classes.labelLine3}>
                    <PopupComponentForLabel
                        link={
                            <div className={classes.link}>
                                <div className={classes.linkShowing}>
                                    <TR name="zoning"></TR>
                                </div>
                                <div className={classes.linkShowing} style={{ marginLeft: 5 }}>
                                    {propZoning}
                                </div>
                            </div>
                        }
                        content={
                            <div className={classes.content}>
                                <p>
                                    <TR name={'zoningIntro'}></TR>
                                </p>
                                <p>
                                    <TR name={'zoningDefinition'}></TR>
                                </p>
                                <p>
                                    <TR name={'R1'}></TR>
                                </p>
                                <p>
                                    <TR name={'R2'}></TR>
                                </p>
                                <p>
                                    <TR name={'R3'}></TR>
                                </p>
                                <p>
                                    <TR name={'R4'}></TR>
                                </p>
                                <p>
                                    <TR name={'R5'}></TR>
                                </p>
                            </div>
                        }
                        title={
                            <div className={classes.title}>
                                <TR name={'landValueZoning'}></TR>
                            </div>
                        }
                        table=""
                        maxWidth="xl"
                    />

                    <div style={{ marginLeft: 8 }}></div>

                    <PopupComponentForLabel
                        link={
                            <div className={classes.link}>
                                <div className={classes.linkShowing}>
                                    <TR name={'lastTimeSold'}></TR>
                                </div>
                                <div className={classes.linkShowing}>
                                    {soldRecords && soldRecords.length > 0 ? (
                                        <p>${convertToM(soldRecordsSort[0].soldPrice)}m</p>
                                    ) : (
                                        <TR name={'noSoldRecord'}></TR>
                                    )}
                                </div>
                            </div>
                        }
                        content={
                            <div>
                                {/* <p className={classes.content}>
                                    <TR name={'primarySchRankingSource'}></TR>
                                    <span style={{ color: '#009E90' }}>
                                        {' '}
                                        <TR name={'primarySchRankingWeb'}></TR>
                                    </span>
                                    <TR name={'primarySchRankingSource2'}></TR>
                                </p>
                                <p className={classes.content}>
                                    <TR name={'icseaIntrol'}></TR>
                                </p> */}
                                <SalePriceHistory
                                    soldRecords={soldRecords}
                                    landSize={landSize}
                                    localData={suburbHistoryPrices}
                                    apt={true}
                                />
                                <div>
                                    <MobileLocalityPriceTrend
                                        locality={locality?.localityName}
                                        soldRecords={soldRecords}
                                        localData={suburbHistoryPrices}
                                        apt={true}></MobileLocalityPriceTrend>
                                </div>
                            </div>
                        }
                        title={
                            <div className={classes.title}>
                                <TR name={'Sold History'}></TR>
                            </div>
                        }
                        table={<div></div>}
                        maxWidth="xl"
                    />

                    <div style={{ marginLeft: 8 }}></div>

                    <PopupComponentForLabel
                        link={
                            <div className={classes.link}>
                                <div className={classes.image}>
                                    <img src="assets/mobileIcons/hc-ico@1x.svg"></img>
                                </div>
                                <div className={classes.linkShowing} style={{ marginLeft: '3px' }}>
                                    {closestStationDistance > 3 ? (
                                        <TR name={'distanceTrainStation'}></TR>
                                    ) : (
                                        new Intl.NumberFormat().format(closestStationDistance?.toFixed(1)) + 'km'
                                    )}
                                </div>
                            </div>
                        }
                        content=""
                        title={
                            <div className={classes.title}>
                                <TR name={'trainStationDistance'}></TR>
                            </div>
                        }
                        maxWidth="xl"
                        table={
                            <div>
                                <p className={classes.tableName}>
                                    {closestStationDistance > 3 ? '' : closestStationName}
                                </p>
                                <div className={classes.tableRow}>
                                    <p className={classes.tableCell}>
                                        {' '}
                                        <TR name={'trainStationDist'}></TR> {'   '}{' '}
                                        {new Intl.NumberFormat().format(closestStationDistance?.toFixed(1))}km
                                    </p>
                                    <p className={classes.tableCell}>
                                        <TR name={'walkingTime'}></TR>
                                        {'   '} {props.property.propInfo?.stationDistance?.closestStationWalkTime}
                                        <TR name={'minute'}></TR>
                                    </p>
                                </div>
                            </div>
                        }
                    />

                    <div style={{ marginLeft: 8 }}></div>

                    <PopupComponentForLabel
                        link={
                            <div className={classes.link}>
                                <div className={classes.linkShowing}>
                                    <TR name={'primaryStarRating'}></TR>
                                </div>
                                <div className={classes.linkShowing}>
                                    {starRating ? starRating : 1}
                                    <img
                                        src="assets/images/svgs/gold_star.svg"
                                        style={{ height: '15px', paddingTop: '3px', paddingLeft: '2px' }}
                                    />
                                </div>
                            </div>
                        }
                        content={
                            <div>
                                <p className={classes.content}>
                                    <TR name={'primarySchStarRatingSource'}></TR>
                                </p>
                                <p className={classes.content}>
                                    <TR name={'icseaIntrol'}></TR>
                                </p>
                            </div>
                        }
                        title={
                            <div className={classes.title}>
                                <TR name={'primaryRankingAtLabel'}></TR>
                            </div>
                        }
                        table={
                            <div>
                                <p className={classes.tableName}>{schoolName}</p>
                                <div className={classes.tableRow}>
                                    <p className={classes.tableCell}>
                                        {' '}
                                        <TR name={'primaryStarRating'}></TR> {'   '} {starRating ? starRating : 1}
                                        <img
                                            src="assets/images/svgs/gold_star.svg"
                                            style={{ height: '15px', paddingTop: '3px', paddingLeft: '2px' }}
                                        />
                                    </p>
                                    <p className={classes.tableCell}>
                                        <TR name={'Card21_icsea'}></TR>
                                        {'   '} {icsea}
                                    </p>
                                </div>
                            </div>
                        }
                        maxWidth="xl"
                    />

                    <div style={{ marginLeft: 8 }}></div>
                    {/* <div style={{ display: 'flex', marginTop: 11, justifyContent: 'space-between', flexDirection: 'row' }}> */}
                    {/* <div className={classes.labelLine1}> */}
                    <PopupComponentForLabel
                        link={
                            <div className={classes.link}>
                                <div className={classes.linkShowing}>
                                    <TR name={'streetPrice'}></TR>
                                </div>
                                <div className={classes.linkShowing}>
                                    {props.property.propInfo?.propPriceCompare.streetPriceHigher === 1 ? (
                                        <TR name={'priceHigher'} />
                                    ) : props.property.propInfo?.propPriceCompare.streetPriceHigher === -1 ? (
                                        <TR name={'priceLower'} />
                                    ) : (
                                        <TR name={'priceNoValue'} />
                                    )}
                                </div>
                            </div>
                        }
                        content={
                            <div>
                                <p className={classes.content}>
                                    <TR name={'statisticPeriod'}></TR>
                                </p>
                                <p className={classes.content}>
                                    <TR name={'statisticDesc1'}></TR>
                                    {props.property.propInfo?.propPriceCompare.streetPriceHigher === 1 ? (
                                        <TR name={'priceHigher'} />
                                    ) : props.property.propInfo?.propPriceCompare.streetPriceHigher === -1 ? (
                                        <TR name={'priceLower'} />
                                    ) : (
                                        <TR name={'priceNoValue'} />
                                    )}
                                    <TR name={'statisticDesc2'}></TR>
                                    {props.property.propInfo?.propPriceCompare.streetTurnoverRate > 1 ? (
                                        <TR name={'holdingLongInPop'}></TR>
                                    ) : (
                                        <TR name={'holdingShortInPop'}></TR>
                                    )}
                                </p>

                                <div>
                                    <Card11 property={streetSolds} mobile={true} />
                                </div>
                            </div>
                        }
                        title={
                            <div className={classes.title}>
                                <TR name={'streetHousePriceLevel'}></TR>
                            </div>
                        }
                        table={
                            <>
                                {!(apartmentPriceRange === null) ? (
                                    <div>
                                        <p className={classes.tableName}>
                                            <TR name={'House'}></TR>：
                                        </p>
                                        <div className={classes.tableRow}>
                                            <p className={classes.tableCell}>
                                                {' '}
                                                <TR name={'streetPropPrice'}></TR> {'   '} $
                                                {convertToM(props.street.apartmentPriceRange.soldPriceRange.minValue)}m
                                                ~ $
                                                {convertToM(props.street.apartmentPriceRange.soldPriceRange.maxValue)}m
                                            </p>
                                            <p className={classes.tableCell}>
                                                <TR name={'soldNumber'}></TR>
                                                {'   '} {props.street.apartmentPriceRange.medianPrice.numberOfSold}
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {!(apartmentPriceRange === null) ? (
                                    <div>
                                        <p className={classes.tableName}>
                                            <TR name={'Apt'}></TR>：
                                        </p>
                                        <div className={classes.tableRow}>
                                            <p className={classes.tableCell}>
                                                {' '}
                                                <TR name={'streetPropPrice'}></TR> {'   '} $
                                                {convertToM(props.street.apartmentPriceRange.soldPriceRange.minValue)}m
                                                ~ $
                                                {convertToM(props.street.apartmentPriceRange.soldPriceRange.maxValue)}m
                                            </p>
                                            <p className={classes.tableCell}>
                                                <TR name={'soldNumber'}></TR>
                                                {'   '} {props.street.apartmentPriceRange.medianPrice.numberOfSold}
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        }
                        maxWidth="xl"
                    />
                    <div style={{ marginLeft: 8 }}></div>

                    <div style={{ marginLeft: 8 }}></div>
                    <div className={classes.googleSearchDiv} onClick={showGoogleSearch}>
                        <TR name="googleSearch"></TR>
                        <img src="assets/mobileIcons/rightArrow.svg"></img>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                </div>
            </div>
        </>
    );
};
